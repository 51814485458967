
import { defineComponent } from "vue";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

export default defineComponent({
  name: "Home",
  components: {

  },
  data(){
    return {
      searchTerm: "",
      data: []
  }
  },
  methods:{
    search(){
      fetch(`https://localhost:3000/?q=${this.searchTerm}`).then((response)=> {
         return response.json();
      }).then((result)=> {
        this.data = result;
      })
    }
  }
});
